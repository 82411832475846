import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Navbar from '../components/navbar/navbarvn';
import Banner from '../components/doi-tac/banner';
import DanhsachLogo from '../components/doi-tac/logo';
import Footer from '../components/homepagevn/Footer';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  grid2: {
    marginTop: theme.spacing(5),
  },
  grid21: {
    marginLeft: theme.spacing(15),
  },
  image: {
    margin: 'auto',
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  contentContainer: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(8),
    },
  },
  content: {
    textAlign: 'justify',
    fontSize: '1.14rem',
    lineHeight: '1.5',
  },
}));

export default function Partner() {
  const classes = useStyles();
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title> ĐỐI TÁC</title>
      </Helmet>
      <Navbar />
      <Banner />
      <Container className={classes.contentContainer}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} className={classes.grid2} />
          <Grid item xs={12} md={6} sm={6}>
            <img alt="" src="https://static.chinhdaisteel.com/Winfire/shuttlerstock-image/shutterstock_688722718.jpg" className={classes.image} />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Typography className={classes.title}> ĐỐI TÁC PHÒNG CHÁY CHỮA CHÁY</Typography>
            <br />
            <Typography className={classes.content}>
              {' '}
              Chúng tôi hợp tác chặt chẽ với các công ty uy tín về
              phòng cháy trên khắp cả nước, đem lại sản phẩm và giải
              pháp chất lượng cho các công trình lớn. Các đối tác đều
              có trên 10 năm kinh nghiệm thi công các công trình lớn của tư nhân,
              công trình vốn ngân sách, công trình có vốn đầu tư nước ngoài…
              Bằng việc hợp tác với các đối tác tư vấn thiết kế, lắp đặt, thi công các
              sản phẩm phòng cháy. Chúng tôi cung cấp giải pháp từ A-Z cho các dự án có
              vốn đầu tư nước ngoài, các gói sản phẩm làm tăng tốc độ thi công, nâng
              tính an toàn, và đảm bảo khả năng nghiệm thu cao nhất cho các công
              trình trên toàn miền Bắc.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sm={6} className={classes.grid2}>
            <Typography className={classes.title}>ĐỐI TÁC CUNG CẤP PHỤ KIỆN </Typography>
            <br />
            <Typography className={classes.content}>
              Chúng tôi hợp tác và sử dụng các phụ kiện chính hãng đạt các tiêu chuẩn
              khắt khe về chất lượng như UL, FM của Hoa Kỳ. Đối tác Vinachin thành
              lập từ năm 2008 chuyên cung cấp phụ kiện cho các công trình lớn như
              Ga Nhổn - Hà Nội, Khách sạn Mariott, Altara Quy Nhơn, Foxconn Hồng Hải.
              Vinachin là một trong những nhà phân phối đầu tiên phụ kiện
              chính hãng XHTONG - Tiêu chuẩn FM UL CE
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} sm={6} className={classes.grid2}>
            <img alt="" src="https://static.chinhdaisteel.com/Winfire/shuttlerstock-image/shutterstock_709232077.jpg" className={classes.image} />
          </Grid>
        </Grid>
      </Container>
      <DanhsachLogo />
      <Footer />
    </>
  );
}
